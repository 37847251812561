<template>
  <r-container>
    <r-row>
      <r-col class="col-12 md-6">
        <r-card
          class="color-gradient-two-45 pa-3 title h-full"
          v-if="visits.today && visits.today > 0"
        >
          {{ $t("you_have_visit") }}
        </r-card>
        <r-card class="color-gradient-three-45 pa-3 title h-full" v-else>
          {{ $t("you_not_visit") }}
        </r-card>
      </r-col>
      <r-col class="col-12 md-6">
        <r-card class="color-gradient-one-45 pa-3 title">
          {{ $t("total_visit") }}
          <div class="display-4 text-end">{{ $n(visits.total) }}</div>
        </r-card>
      </r-col>
      <r-col class="col-12" v-for="(chart, i) in charts" :key="i">
        <r-card class="w-full h-full">
          <r-chart
            width="800"
            height="400"
            :type="chart.type"
            :items="chart.items"
            :options="chart.options"
          ></r-chart>
        </r-card>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      visits: {},
      charts: [],
    };
  },
  created() {
    this.$axios.get("user/chart").then(({ data }) => {
      this.visits = data.visits;
      let charts = data.charts;
      for (let i = 0; i < charts.length; i++) {
        let item = charts[i];
        if (item.type === "line") {
          for (let j = 0; j < item.items.labels.length; j++) {
            item.items.labels[j] = this.$d(
              new Date(item.items.labels[j]),
              "short"
            );
          }
        }
      }

      this.charts = charts;
    });
  },
  methods: {},
};
</script>
